import {Injectable} from "@angular/core";
import {Action, createSelector, State, StateContext} from "@ngxs/store";
import {StatusService} from "../../api-services/legacy-milestone-api/status/status.service";
import {IResourceStatusModel} from "./models/resource-status.model";
import {ResourceHierarchy} from "../../api-services/legacy-milestone-api/milestone/models/resource-hierarchy.model";
import {LoadingState} from "../loading-state.enum";
import {catchError, EMPTY, tap} from "rxjs";

export class GetStatusesForResources {
  static readonly type = '[Status] Get Statuses For Resources';

  constructor(public resources: ResourceHierarchy[]) {
  }
}

@State<IResourceStatusModel[]>({
  name: 'status',
  defaults: [],
})
@Injectable()
export class StatusState {

  constructor(private statusService: StatusService) {
  }

  public static getStatusForResource(resource: ResourceHierarchy) {
    return createSelector([StatusState], (state: IResourceStatusModel[]) => {
      return state.find(s => this.getDeepestResourceId(s.hierarchy) === this.getDeepestResourceId(resource)) ?? null;
    });
  }

  private static getDeepestResourceId(hierarchy: ResourceHierarchy): number {
    return hierarchy.purchaseOrderId ?? hierarchy.ticketId ?? hierarchy.contractId;
  }

  @Action(GetStatusesForResources)
  getStatusesForResources(ctx: StateContext<IResourceStatusModel[]>, action: GetStatusesForResources) {
    this.initializeStatusContainers(ctx, action.resources);

    return this.statusService.getStatuses(action.resources).pipe(
      tap(statuses => {
        const state = ctx.getState();
        const newState = [...state];
        for (const status of statuses) {
          const index = newState.findIndex(s => StatusState.getDeepestResourceId(s.hierarchy) === StatusState.getDeepestResourceId(status.hierarchy));
          newState[index] = {
            state: LoadingState.loaded,
            hierarchy: status.hierarchy,
            status: status.status,
            milestone: status.milestone,
          };
        }
        ctx.setState(newState);
      }),
      catchError(() => {
        const state = ctx.getState();
        const newState = [...state];
        for (const status of action.resources) {
          const index = newState.findIndex(s => StatusState.getDeepestResourceId(s.hierarchy) === StatusState.getDeepestResourceId(status));
          newState[index] = {
            ...newState[index],
            state: LoadingState.error,
          };
        }
        ctx.setState(newState);

        return EMPTY;
      })
    );
  }

  private initializeStatusContainers(ctx: StateContext<IResourceStatusModel[]>, resources: ResourceHierarchy[]): void {
    const state = ctx.getState();
    let newContainers: IResourceStatusModel[] = [];
    resources.forEach(resource => {
      const index = state.findIndex(s => StatusState.getDeepestResourceId(s.hierarchy) === StatusState.getDeepestResourceId(resource));
      if (index === -1) {
        newContainers.push({
          state: LoadingState.loading,
          hierarchy: resource,
          status: null,
          milestone: null,
        });
      } else {
        state[index] = {
          ...state[index],
          state: LoadingState.loading,
        };
      }
    });
    ctx.setState([...state, ...newContainers]);
  }

}
