import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IStatusOverviewModel} from "./models/status-overview.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ResourceHierarchy} from "../milestone/models/resource-hierarchy.model";
import {IStatusResultModel} from "./models/status-result.model";

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private http: HttpClient) {
  }

  getOverview(hierarchy: ResourceHierarchy): Observable<IStatusOverviewModel[]> {
    let params: HttpParams = new HttpParams().set('contractId', hierarchy.contractId);

    if (hierarchy.ticketId != null) {
      params = params.set('ticketId', hierarchy.ticketId);
    }

    if (hierarchy.purchaseOrderId != null) {
      params = params.set('purchaseOrderId', hierarchy.purchaseOrderId);
    }

    return this.http.get<IStatusOverviewModel[]>(`${environment.milestoneApiBaseUrl}/status/overview`, {
      params: params
    });
  }

  getStatuses(hierarchies: ResourceHierarchy[]): Observable<IStatusResultModel[]> {
    return this.http.post<IStatusResultModel[]>(`${environment.milestoneApiBaseUrl}/status`, hierarchies);
  }

}
